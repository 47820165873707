<template>
  <div class="account-page-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <ul class="nav myaccount-tab-trigger" id="account-page-tab" role="tablist">
            <li class="nav-item">
              <router-link to="/user" exact class="nav-link">
                {{ $i18n.User.Menu.Order }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/user/coupon" exact class="nav-link">
                {{ $i18n.User.Menu.coupon }}
              </router-link>
            </li> 
            <li class="nav-item">
              <router-link to="/user/password" class="nav-link">
                {{ $i18n.User.Menu.Password }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/user/address" class="nav-link">
                {{ $i18n.User.Menu.Address }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/user/userInfo" exact class="nav-link">
                {{ $i18n.User.Menu.Info }}
              </router-link>
            </li>
            <li @click="logout" class="nav-item">
              <a class="nav-link" id="account-logout-tab" role="tab" aria-selected="false">{{
                $i18n.User.Menu.Logout
              }}</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-9">
          <div class="tab-content myaccount-tab-content" id="account-page-tab-content">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['token'])
  },
  created() {
    if (!this.token) {
      return this.$router.replace('/')
    }
  },
  methods: {
    open() {},
    logout() {
      const User = this.$i18n.User
      this.$confirm(User.ConfirmLogout, User.ConfirmLogoutTitle, {
        confirmButtonText: User.ConfirmButtonText,
        cancelButtonText: User.CancelButtonText,
        type: 'warning'
      })
        .then(() => {
          this.$toasted.success(User.ClearSuccess)
          this.$store.dispatch('clearData')
          this.$router.replace('/')
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="less">
@border-color: #ebebeb;
@body-text_color: #333333;
@primary-color: #fed100;
@bg-white_color: #fff;
@bg-nobel_color: #999999;
@heading-color: #333;

.account-page-area {
  padding: 90px 0 50px 0;

  .shipping-address {
    .fr-box {
      margin-bottom: 10px;
    }

    .fr {
      float: right;
      margin-left: 10px;
    }

    .torress-btn {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      font-weight: 400;
      min-width: 80px;
      width: auto;
      padding: 0 10px;
      display: inline-block;
      border-radius: 0;

      & + .torress-btn {
        margin-left: 10px;
      }
    }
  }

  .myaccount-tab-trigger {
    display: block;

    li {
      display: block;

      &:first-child {
        margin-top: -1px;
      }

      a {
        border: 1px solid @heading-color;
        display: block;
        background: @body-text_color;
        color: @bg-white_color;
        text-transform: uppercase;
        font-weight: 600;
        padding: 10px 20px;

        &.router-link-active {
          background: @primary-color;
          border-color: @primary-color;
          color: @bg-white_color;

          &:hover {
            color: @bg-white_color !important;
          }
        }

        &:hover {
          color: @primary-color;
        }
      }
    }
  }
}

/* ---My Account Tab Content--- */
.myaccount-tab-content {
  border: 1px solid @border-color;
  padding: 20px 10px;

  @include breakpoint(xx-small) {
    padding: 20px;
  }
}

.myaccount-dashboard {
  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.myaccount-orders {
  .table {
    margin-bottom: 0;

    .account-order-id {
      &:hover {
        color: @primary-color;
      }
    }

    &.table-hover {
      tbody {
        tr {
          &:hover {
            background-color: rgba(0, 0, 0, 0.02);
          }
        }
      }
    }

    td,
    th {
      vertical-align: middle;
      text-align: center;

      @include breakpoint(max-small) {
        min-width: 180px;
      }
    }
  }

  .small-title {
    margin-bottom: 15px;
  }
}

.myaccount-address {
  @include breakpoint(xx-small) {
    .col {
      flex-basis: unset;
    }
  }

  h4 {
    &.small-title {
      @include breakpoint(max-small) {
        font-size: 18px;
      }
    }
  }
}

/* ---My Account Form Style--- */
.torress-form,
.torress-form-boxed {
  padding: 30px;
  border-radius: 0;
  border: 1px solid @border-color;

  .torress-form-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: -20px;

    .single-input {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 20px;

      .torress-btn {
        font-size: 14px;
        text-transform: capitalize;

        &:hover {
          background-color: @primary-color;
          color: @bg-white_color !important;
        }
      }

      &.single-input-half {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50% - 15px);
        flex: 0 0 calc(50% - 15px);
        max-width: calc(50% - 15px);
      }

      label {
        margin-bottom: 8px;
        display: block;
      }

      input,
      select,
      textarea {
        padding: 0 15px;
        border: 1px solid @border-color;
        height: 40px;
        width: 100%;
      }

      input {
        &:first-child {
          margin-top: 20px;
        }
      }

      .checkbox-input {
        display: inline-block;

        label {
          display: inline-block;
        }
      }
    }

    a {
      font-size: 13px;

      &:hover {
        color: @primary-color;
      }
    }
  }
}
</style>
